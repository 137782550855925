.content_service_box {
  /*  padding-top: 15vw; */
  width: 95%;
  height: 45%;
  overflow: hidden;
  margin: auto auto;

  /* background-color: rgb(243, 125, 125); */
}

.content_service_img {
  width: 100%;
  height: 120vw;
  border-radius: 1vw;
}

.content_service_box_spinner {
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
