/* @font-face {
    font-family:'Monotype Corsiva';
    src: url('/assets/fonts/Monotype_Corsiva.ttf') format('ttf');
    font-weight: normal;
  font-style: normal;
  }
 */

@font-face {
  font-family: "Monotype Corsiva";
  src: url("../../assets/fonts/monotype-corsiva/monotype-corsiva.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/monotype-corsiva/monotype-corsiva.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/fonts/monotype-corsiva/monotype-corsiva.otf") format("opentype"),
    /* Open Type Font */ url("../../assets/fonts/monotype-corsiva/monotype-corsiva.svg")
      format("svg"),
    /* Legacy iOS */ url("../../assets/fonts/monotype-corsiva/monotype-corsiva.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../../assets/fonts/monotype-corsiva/monotype-corsiva.woff")
      format("woff"),
    /* Modern Browsers */ url("../../assets/fonts/monotype-corsiva/monotype-corsiva.woff2")
      format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

.arrow_box_up {
  width: 50px;
  height: 20px;
  position: relative;
  margin: auto auto;
  cursor: pointer;
}

.arrow_box_up .arrow_left {
  width: 70px;
  height: 5px;
  background-color: rgb(202, 202, 202);
  position: absolute;
  right: 20px;
  transform: rotate(-20deg);
}

.arrow_box_up .arrow_right {
  width: 70px;
  height: 5px;
  background-color: rgb(202, 202, 202);
  position: absolute;
  left: 23px;
  transform: rotate(20deg);
}

.arrow_box_up:hover .arrow_left {
  height: 7px;
  background-color: rgb(143, 143, 143);
}
.arrow_box_up:hover .arrow_right {
  height: 7px;
  background-color: rgb(143, 143, 143);
}

.arrow_box_dawn {
  width: 50px;
  height: 20px;
  position: relative;
  margin: auto auto;
  margin-bottom: 60px;
  cursor: pointer;
  transform: rotate(180deg);
}

.arrow_box_dawn .arrow_left {
  width: 70px;
  height: 5px;
  background-color: rgb(202, 202, 202);
  position: absolute;
  right: 22px;
  transform: rotate(-20deg);
}

.arrow_box_dawn .arrow_right {
  width: 70px;
  height: 5px;
  background-color: rgb(202, 202, 202);
  position: absolute;
  right: -41px;
  transform: rotate(20deg);
}

.arrow_box_dawn:hover .arrow_left {
  height: 7px;
  background-color: rgb(143, 143, 143);
}
.arrow_box_dawn:hover .arrow_right {
  height: 7px;
  background-color: rgb(143, 143, 143);
}

.web-working-img-container {
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 22% 22% 22% 22%;
  grid-template-rows: 47% 47%;
  grid-template-areas:
    "slide1 slide2 slide3 slide4 "
    "slide5 slide6 slide7 slide8";
  grid-gap: 20px;
}

.info_blocks_wrapper {
  width: 100%;
  height: 330px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  /*     background-color: rgb(204, 255, 138); */
}

.info_block_box {
  width: 230px;
  height: 250px;
  background-color: #f4f4f4;
  border-radius: 20px;
}

.info_block_title {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-decoration: underline;
  color: #d500da;
}

.info_block_ul {
  list-style-type: none;
  line-height: 30px;
  margin: 0;
  padding: 0;
}

.info_block_li {
  font-size: 14px;
  padding-left: 30px;
  cursor: pointer;
}

.info_block_li:hover {
  font-weight: bold;
  background-color: #fff;
  color: #d500da;
}

/* Styles for reviews section */
.reviews_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.reviews_content_box {
  width: 90%;
  height: 90%;
}
.reviews_content_box_ul {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-around;
  padding: 0;
  margin: 3% 0 0 0;
}

.reviews_content_box_li {
  width: 30%;
  height: 90%;
  box-sizing: border-box;
  padding: 10px;
  margin: 0;
  border-radius: 5px;
  list-style-type: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #f4f4f4;
}

.reviews_content_box_li_name {
  font-size: 16px;
}

.reviews_content_box_li_rating {
  height: 10%;
}

.reviews_content_box_li_review {
  width: 100%;
  height: 60%;
  overflow-y: auto;
}

.reviews_content_box_li_review_date {
  height: 15%;
  display: flex;
  align-items: center;
}

.reviews_pagination_box {
  width: 90%;
  height: 10%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.reviews_current_page_review_box {
  width: 15%;
  text-align: center;
}
.reviews_current_page_review {
  font-size: 14px;
  font-weight: bold;
  color: #d500da;
}

.reviews_svg_wrapper {
  width: 10%;
  height: 60%;
  text-align: center;
}

.reviews_svg_box {
  width: 80%;
  height: 100%;
  cursor: pointer;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}
.rotate {
  transform: rotate(180deg);
}
.reviews_svg_box:hover {
  filter: none;
}
.reviews_svg_arrow_right {
  fill: #8a8a8a;
}

.reviews_svg_box:hover .reviews_svg_arrow_right {
  fill: #a067a1;
}

.reviews_svg_box:hover .reviews_svg_arrow_left {
  fill: #a067a1;
}

.contacts_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  outline: 1px solid #a067a1;
  outline-offset: -1px; /* Выводим рамку внутри элемента */
}

.contacts_top_box {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
}

.contacts_top_info {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.contacts_top_logo {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contacts_top_img {
  height: 70%;
  cursor: pointer;
}

.contacts_top_url {
  font-family: "Monotype Corsiva";
  font-size: 2.5vw;
  color: #d500da;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}
.contacts_nav_img_css {
  width: 40%;
  height: 100%;
  color: #a067a1;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contacts_top_description {
  width: 60%;
  height: 100%;
  font-family: Monotype Corsiva;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.contacts_top_descript_title {
  font-size: 4.5vw;
  color: #d500da;
  margin-top: 2vw;
}

.contacts_top_descript_ul_box {
  width: 100%;
}

.contacts_top_descript_ul {
  display: inline-block;
  font-size: 2vw;
  margin-top: 2vw;
  color: #a067a1;
  line-height: 3vw;
  text-align: left;
  list-style-type: none;
  padding: 0;
}

.contacts_nav_css {
  color: #a067a1;
  text-decoration: none;
}

.contacts_top_footer {
  width: 100%;
  height: 30%;
  font-family: Monotype Corsiva;
  font-size: 50px;
  color: #d500da;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.contacts_top_footer_button {
  width: 15vw;
  height: 2.5vw;
  font-size: 1vw;
  font-size: 1.7vw;
  font-family: Monotype Corsiva;
  font-weight: bold;
  color: #d500da;
  background-color: #fffb99;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  transition-duration: 1s;
}

.contacts_top_footer_button:hover {
  color: #fffb99;
  background-color: #d500da;
}

.contacts_footer_box {
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: #d500da;
}

.contacts_footer_instagram {
  width: 50%;
  height: 100%;
  font-family: Monotype Corsiva;
  font-size: 2.5vw;
  color: rgb(206, 206, 206);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.contacts_footer_mail {
  width: 50%;
  height: 100%;
  font-family: Monotype Corsiva;
  font-size: 2.5vw;
  color: rgb(206, 206, 206);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.contacts_footer_mail_link {
  text-decoration: none;
  color: rgb(206, 206, 206);
}

.contacts_cursor {
  cursor: pointer;
}

/* Cooperation form css */
.cooperation_title {
  width: 100%;
  color: #d500da;
  font-size: 2vw;
  font-weight: bold;
  text-align: center;
}
.cooperation_form {
  width: 100%;
  height: 100%;
}

.cooperation_form_question {
  color: #9932cc;
  display: block;
  text-align: left;
}

.cooperation_form_onlinestore_box {
  width: 100%;
  height: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 4%;
}

.cooperation_form_onlinestore_input {
  width: 45%;
  height: 22px;
  border: none;
  outline: none;
  border-bottom: 2px solid #8a8a8a;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  color: #9932cc;
  background-color: transparent;
  margin-top: 2%;
  text-indent: 1vw;
}

.cooperation_form_label_text {
  margin-left: 3px;
  vertical-align: -0.15em;
}

.cooperation_form_cooperation_textarea_box {
  width: 100%;
  height: 5vw;
  text-align: center;
  margin-top: 4%;
}

.cooperation_form_cooperation_textarea {
  width: 80%;
  box-sizing: border-box;
  height: 3vw;
  resize: none;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #9932cc;
  border: thin dotted rgb(0, 0, 0);
  outline: none;
}

.cooperation_form_about_textarea_box {
  width: 100%;
  height: 10vw;
  text-align: center;
  margin-top: 2%;
}

.cooperation_form_about_textarea {
  width: 80%;
  box-sizing: border-box;
  height: 8vw;
  resize: none;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #9932cc;
  border: thin dotted rgb(0, 0, 0);
  outline: none;
}

.cooperation_form_contacts_box {
  width: 100%;
  height: 10vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.cooperation_form_contacts_first_block {
  width: 50%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* background-color: rgb(247, 111, 111); */
}

.cooperation_form_contacts_second_block {
  width: 50%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.cooperation_form_contacts_input {
  width: 60%;
  height: 22px;
  border: none;
  outline: none;
  border-bottom: 2px solid #8a8a8a;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  color: #9932cc;
  background-color: transparent;
  margin-top: 2%;
}

.cooperation_form_button_box {
  width: 50%;
  height: 5vw;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto auto;
}

.cooperation_form_button_center {
  width: 50%;
  text-align: center;
}

.cooperation_form_button_back {
  width: 10vw;
  height: 2.5vw;
  font-size: 1vw;
  font-size: 1.7vw;
  font-family: Monotype Corsiva;
  font-weight: bold;
  color: #d500da;
  background-color: #fffb99;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  transition-duration: 1s;
}

.cooperation_form_button_back:hover {
  color: #fffb99;
  background-color: #d500da;
}

.cooperation_form_button_send {
  width: 10vw;
  height: 2.5vw;
  font-size: 1vw;
  font-size: 1.7vw;
  font-family: Monotype Corsiva;
  font-weight: bold;
  color: darkgreen;
  background-color: #fffb99;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  transition-duration: 1s;
}

.cooperation_form_button_send:hover {
  color: #fffb99;
  background-color: darkgreen;
}

.cooperation_underline {
  height: 1px;
  color: #d500da;
  background-color: #d500da;
}

.cooperation_underline_txt {
  width: 100%;
  text-align: center;
}
