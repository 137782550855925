.footer_socials_box {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
}

.footer_socials_box_margin {
  margin-right: 100px;
  margin-bottom: 15px;
}

.footer_form_callback {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-right: 10px;
}

.footer_form_input_box {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
}

.footer_form_input {
  width: 80%;
  height: 1.7vw;
  border: none;
  outline: none;
  border-bottom: 2px solid #8a8a8a;
  margin-top: 20px;
  text-align: center;
  font-size: 22px;
  line-height: 22px;
  color: #9932cc;
}

.footer_form_input_notValid {
  border-bottom: 2px solid rgb(182, 0, 0);
}

.footer_form_input::placeholder {
  color: rgb(189, 189, 189);
  font-size: 18px;
}

.footer_input_subtitle {
  margin: 0;
  padding: 0;
  color: #8a8a8a;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.footer_socials_circle {
  width: 3.5vw;
  height: 3.5vw;
  margin-right: 3vw;
}

.footer_socials_circle_mob {
  width: 13vw;
  height: 13vw;
  /* margin-right: 3vw; */
}

/* .footer_socials_circle:nth-child(3n){
    margin-right: 0;
} */

.footer_socials_form_btn {
  border: none;
  outline: none;
  background-color: transparent;
}

.footer_socials_svg_box {
  width: 3.5vw;
  height: 3.5vw;
}

.footer_socials_svg_box_mob {
  width: 13vw;
  height: 13vw;
}

.footer_socials_svg {
  fill: #8a8a8a;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform-origin: 50% 50% 0;
  transform-origin: 50% 50% 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  cursor: pointer;
}

.footer_socials_circle:hover .footer_socials_svg {
  fill: #9932cc;
  -webkit-transition: transform 0.5s;
  -webkit-transform-origin: 50% 50% 0;
  -webkit-transform: rotate(360deg);
  transition: transform 0.5s;
  transform-origin: 50% 50% 0;
  transform: rotate(360deg);
}
