@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
body {
  padding: 0;
  margin: 0;
  -webkit-user-select: auto;
  user-select: auto;
}

.main_loader__TwHxG {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
}

.main_order_checkout_np_list_spinner__VZjY0 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.main_wrapper__2L15d {
  margin: 10px;
  font-family: "Roboto", sans-serif;
  position: relative;
}

.main_notFound_wrapper__3eoTQ {
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  color: rgb(182, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_mob_wrapper__3Rajl {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
}

.main_mob_content_box__lsreF {
  margin-top: 15vw;
}

.main_mob_footer_box__l5A7i {
  width: 100%;
  height: 20vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

@font-face {
  font-family: "Monotype Corsiva";
  src: url("/assets/fonts/Monotype_Corsiva.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

.header_header__2BdPQ {
  width: 99%;
  height: 120px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fff;
  position: relative;
  position: fixed;
  top: 0;
  z-index: 9000;
}

.header_header_line__smO9N {
  width: 90%;
  height: 2px;
  background-color: #d500da;
  position: fixed;
  top: 120px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 9000;
}

/* Стиль для Лого */
.header_logo-img__mRrGG {
  width: 250px;
  height: 95px;
  margin-right: 65px;
}

/* Стиль блока Навигации */

.header_nav-shedule__2l4OE {
  text-align: center;
  margin-bottom: 20px;
}
.header_nav-ul__3aS10 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 60px;
  text-align: center;
}

.header_nav-ul__3aS10 li {
  width: 10vw;
}

.header_nav-li__11xU- {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  color: black;
}

.header_nav-li__11xU-:hover {
  font-size: 28px;
  color: #d500da;
}

.header_nav-li__11xU-:after {
  display: block;
  content: "";
  height: 2px;
  width: 0%;
  background-color: #d500da;
  transition: width 0.3s ease-in-out;
  margin: auto auto;
}

.header_nav-li__11xU-:hover:after {
  width: 70%;
  color: #d500da;
}

.header_activeStyle__1_r1v {
  color: #d500da;
  font-size: 28px;
  font-weight: bold;
  text-decoration: none;
}
.header_activeStyle__1_r1v:after {
  display: block;
  content: "";
  height: 2px;
  width: 0%;
  background-color: #d500da;
  transition: width 0.3s ease-in-out;
  margin: auto auto;
}

.header_activeStyle__1_r1v:hover:after {
  width: 70%;
  color: #d500da;
}

/* Стиль блока Контактов */
.header_messangers-box__1O5N4 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.header_messangers_number__1A0tl {
  margin-bottom: 15px;
  font-weight: bold;
}

.header_messangers_number_mob__ecnD7 {
  font-family: Monotype Corsiva;
  font-size: 7vw;
  font-weight: bold;
  color: #a067a1;
  text-align: center;
}

.header_messangers_number_link__2ieyq {
  text-decoration: none;
  color: black;
}

.header_messangers_number_link_mob__YpkLi {
  text-decoration: none;
  color: #a067a1;
}

.header_messangers-img-box__3qzb6 {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.header_messangers-img-box_mob__aBIA- {
  width: 100%;
  height: 12vw;
  margin-top: 7vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  background-color: #d500da;
}

.header_messangers_btn__Neuis {
  width: 35px;
  height: 35px;
  outline: none;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.header_messangers_btn_mob__vl90x {
  width: 10vw;
  height: 10vw;
  outline: none;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.header_messangers_svg_box__3tP91 {
  width: 35px;
  height: 35px;
  position: absolute;
  left: 0;
  top: 0;
}

.header_messangers_svg_box_mob__3LNzK {
  width: 10vw;
  height: 10vw;
  position: absolute;
  left: 0;
  top: 0;
}

.header_messangers_svg_viber__GKeyr {
  fill: #8a8a8a;
  cursor: pointer;
}

.header_messangers_btn__Neuis:hover {
  box-shadow: none;
}

.header_messangers_btn__Neuis:hover .header_messangers_svg_viber__GKeyr {
  -webkit-animation: header_viber__2fy1A 1s ease-in-out forwards;
          animation: header_viber__2fy1A 1s ease-in-out forwards;
  box-shadow: none;
}

@-webkit-keyframes header_viber__2fy1A {
  0% {
    fill: #8a8a8a;
  }

  100% {
    fill: #7f4da0;
  }
}

@keyframes header_viber__2fy1A {
  0% {
    fill: #8a8a8a;
  }

  100% {
    fill: #7f4da0;
  }
}

.header_messangers_svg_whatsapp__Hr6Ga {
  fill: #8a8a8a;
  cursor: pointer;
}

.header_messangers_btn__Neuis:hover .header_messangers_svg_whatsapp__Hr6Ga {
  -webkit-animation: header_whatsapp__hR5n1 1s ease-in-out forwards;
          animation: header_whatsapp__hR5n1 1s ease-in-out forwards;
  box-shadow: none;
}
@-webkit-keyframes header_whatsapp__hR5n1 {
  0% {
    fill: #8a8a8a;
  }

  100% {
    fill: #25d366;
  }
}
@keyframes header_whatsapp__hR5n1 {
  0% {
    fill: #8a8a8a;
  }

  100% {
    fill: #25d366;
  }
}

.header_messangers_svg_telegram__gGqnq {
  fill: #8a8a8a;
  cursor: pointer;
}

.header_messangers_btn__Neuis:hover .header_messangers_svg_telegram__gGqnq {
  -webkit-animation: header_telegram__2YmEv 1s ease-in-out forwards;
          animation: header_telegram__2YmEv 1s ease-in-out forwards;
  box-shadow: none;
}
@-webkit-keyframes header_telegram__2YmEv {
  0% {
    fill: #8a8a8a;
  }

  100% {
    fill: #2fa0d2;
  }
}
@keyframes header_telegram__2YmEv {
  0% {
    fill: #8a8a8a;
  }

  100% {
    fill: #2fa0d2;
  }
}

/* Стиль блока Корзины заказов */
.header_basket-img__6s1ZV {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.header_basket-box__UW2da {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.header_basket-order-box__1wKYH {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  line-height: 30px;
}
.header_basket-title__ZjTBQ {
  font-size: 14px;
  font-weight: bold;
}
.header_basket-order-box-quantity__2nDcW {
  width: 50px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.header_basket-order-devideLine__251I_ {
  padding: 0 5px 0 5px;
}

.header_basket-order-box-price__dx6ju {
  width: 80px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.header_mob_header_basket_link_styles__25ujg {
  text-decoration: none;
  color: rgb(253, 253, 253);
  text-align: right;
  line-height: 5vw;
}

.header_mob_header_basket_quantity__1V_UT {
  margin-right: 2em;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
}

.header_mob_header_basket_price__Og-Ki {
  margin-right: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
}

.header_topBar-box__3ffN8 {
  width: 100%;
  height: 60px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #d500da;
  margin-top: 120px;
  margin-bottom: 20px;
}

.header_topBar-ul__Cg-I6 {
  width: 100%;
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
}

/* .topBar-ul li{
    width: 14vw;
} */

.header_topBar-li__3rqnj {
  height: 100%;
  color: #efefef;
  text-decoration: none;
  cursor: pointer;
}

.header_topBar-li__3rqnj:after {
  display: block;
  content: "";
  height: 2px;
  width: 0%;
  background-color: #efefef;
  transition: width 0.3s ease-in-out;
  margin: auto auto;
}

.header_topBar-li__3rqnj:hover:after {
  width: 100%;
}

.header_topBar-logo-img__153SD {
  width: 80px;
  height: 40px;
}

.modal_modal_card_wrapper__3wmDh {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9001;
  background-color: rgb(0, 0, 0);
  opacity: 0.95;
}

.modal_modal_card_container__72fjD {
  width: 85vw;
  height: 45vw;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 9992;
  box-sizing: border-box;
  border: 2px solid #727272;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.modal_mob_modal_card_container__3RCSN {
  width: 85vw;
  height: 120vw;
  background-color: #fff;
  position: fixed;
  top: 55%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 9992;
  box-sizing: border-box;
  border: 2px solid #727272;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.modal_mob_modal_container_order__129aO {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(214, 68, 68); */
  position: absolute;
  top: 0vw;
  overflow: hidden;
  overflow: scroll;
}

.modal_mob_modal_container_order_phone__2KI9F {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.modal_modal_card_container_title_box__2vz6T {
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
}

.modal_mob_modal_card_container_title_box__GlVl2 {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 5vw;
  font-weight: bold;
  color: #818181;
  /*   overflow: auto !important;
  -webkit-overflow-scrolling: auto !important;
  -webkit-overflow-scrolling: touch !important; */

  /*   overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; */
  /* -webkit-overflow-scrolling-y: touch; */
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modal_modal_card_container_title_txt_box___Qi2P {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 3vw;
  font-weight: bold;
  color: #818181;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modal_mob_modal_basket_card_header_box__3oS0U {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modal_mob_modal_card_container_title_txt__2M7JD {
  width: 100%;
  font-size: 5vw;
  font-weight: bold;
  color: #818181;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modal_modal_card_container_orders_title_txt__3FwQb {
  width: 100%;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #818181;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.modal_modal_card_container_btn_back_box__3IC-w {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 25px 0 0;
}
/* .mob_modal_card_container_btn_back_box {
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 25px 0 0;
}
 */
.modal_modal_card_container_btn_back_txt__3OjOQ {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #818181;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
}

.modal_mob_modal_card_container_btn_back_txt__EBqaq {
  font-size: 4vw;
  font-weight: bold;
  text-align: center;
  color: #a067a1;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
}

.modal_modal_card_container_btn_back_txt__3OjOQ:hover {
  color: #a067a1;
  text-shadow: none;
}

.modal_modal_price_box__2MQ2G {
  width: 40%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}
.modal_mob_modal_price_box__3WcfD {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.modal_modal_basket_card_counter_box__6Unsr {
  width: 10%;
  height: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.modal_mob_modal_basket_card_counter_box__jQXLR {
  width: 20%;
  height: 8vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.modal_basket_card_counter__pDOlJ {
  width: 100%;
  height: 20px;
  outline: none;
  box-shadow: 1px 1px 2px 0 green;
  border: none;
}

.modal_mob_basket_card_counter__AJRWt {
  width: 50%;
  height: 60px;
  font-size: 3vw;
  outline: none;
  box-shadow: 1px 1px 2px 0 green;
  border: none;
}

.modal_modal_basket_card_counter_txt__331tw {
  color: grey;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
.modal_mob_modal_basket_card_counter_txt__1_dYI {
  color: grey;
  font-size: 3vw;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.modal_modal_card_container_title_price_box__11fzE {
  width: 40%;
  height: 40px;
  border-radius: 20px;
  border: solid;
  border-color: #818181;
  color: #818181;
  font-size: 18px;
  text-align: center;
  line-height: 40px;
  overflow: hidden;
  white-space: pre;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modal_mob_modal_card_container_title_price_box__3Qj1Q {
  width: 40%;
  height: 80px;
  border-radius: 20px;
  border: solid;
  border-color: #818181;
  color: #818181;
  font-size: 5vw;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  line-height: 40px;
  overflow: hidden;
  white-space: pre;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modal_modal_card_container_content_box__vbUj4 {
  width: 95%;
  height: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.modal_mob_modal_card_container_content_box__26NnC {
  width: 95%;
  height: 70%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.modal_modal_card_container_content_txt_box__JRcHB {
  width: 70%;
  height: 95%;
  text-align: justify;
  color: #818181;
  font-size: 13px;
  line-height: 18px;
  overflow-y: Auto;
  box-sizing: border-box;
  padding: 0 10px 0 10px;
  /* background-color: rgb(255, 131, 131); */
}
.modal_mob_modal_card_container_content_txt_box__1odJP {
  width: 95%;
  text-align: justify;
  font-weight: normal;
  font-size: 4vw;
  box-sizing: border-box;
  padding: 0 10px 0 10px;
  line-height: 4.2vw;
  /*   background-color: rgb(131, 143, 255); */
}

.modal_modal_card_container_content_img_box__3Es4C {
  width: 30%;
  height: 95%;
}

.modal_mob_modal_card_container_content_img_box__1rQMW {
  width: 60%;
  height: 50%;
  padding: 4vw;
}

.modal_modal_card_container_content_img__hjIzd {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: zoom-in;
}

.modal_modal_card_container_content_img__hjIzd:active {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: fixed;
  top: 0%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 10px;
  border: 1px solid black;
  background-color: rgba(209, 0, 218, 0.7);
  z-index: 9993;
}

.modal_modal_card_review_form__27Nmd {
  height: 100%;
}

.modal_modal_card_review_title__2oMzK {
  width: 100%;
  font-size: 2vw;
  color: #a067a1;
  text-align: center;
}

.modal_modal_card_star_rating__2uVXj {
  width: 65%;
  outline: none;
  display: flex;
  justify-content: flex-end;
}

.modal_mob_modal_card_star_rating__38MIl {
  width: 60%;
  outline: none;
  display: flex;
  justify-content: center;
}

.modal_modal_card_input_name__BZ6Rt {
  width: 60%;
  font-size: 14px;
  color: #a067a1;
  outline: none;
  border: none;
  border-bottom: 1px solid grey;
}

.modal_order_checkout_add_textarea_box__1NbCf {
  height: 50%;
}

.modal_modal_card_input_review__2ZrXr {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  font-size: 14px;
  color: #a067a1;
  background: #f4f4f4;
  outline: none;
  border: none;
  overflow-y: auto;
  resize: none;
}

.modal_order_card_send_btn_review_box__2JEWz {
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_order_card_send_btn_review__nPOcD {
  width: 150px;
  height: 30px;
  font-size: 14px;
  letter-spacing: 1px;
  background-color: #b8f5b8;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #a067a1;
  cursor: pointer;
  outline: none;
}

.modal_order_card_send_btn_review__nPOcD:hover {
  background-color: #fffb99;
}

.modal_modal_card_container_order_box__1tB5V {
  width: 95%;
  height: 15%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.modal_mob_modal_card_container_order_box__2-pqe {
  width: 95%;
  height: 15vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(26, 13, 206); */
}

.modal_modal_card_container_content_order_box__pMhGX {
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(255, 255, 255); */
}

.modal_mob_modal_card_container_content_order_box__2PUbl {
  width: 33.3%;
  height: 8vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(255, 255, 255); */
}

.modal_modal_card_container_content_order_btn__wrKF7 {
  width: 250px;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  color: #a067a1;
  text-transform: uppercase;
  background: #b8f5b8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}
.modal_mob_modal_card_container_content_order_btn__15oUs {
  width: 250px;
  height: 8vw;
  font-size: 3.5vw;
  font-weight: bold;
  color: #a067a1;
  text-transform: uppercase;
  background: #b8f5b8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.modal_modal_card_container_content_order_btn_active__3lcEc {
  background: #fffb99;
}
.modal_modal_card_container_content_order_btn__wrKF7:hover {
  background: #fffb99;
}

/* Модалка TopBar */
.modal_modal_topbar_container__1tiv5 {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.modal_page_counter_ul__kFCyV {
  list-style-type: none;
  height: 10%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.modal_page_counter_li__3U76P {
  width: 20px;
  height: 10px;
  cursor: pointer;
}

.modal_page_counter_li_circle__3iSSL {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: rgb(124, 124, 124);
}

.modal_page_counter_li_circle__3iSSL:hover {
  -webkit-transform: scale(1.03, 1.03);
          transform: scale(1.03, 1.03);
  background-color: rgb(122, 0, 0);
}

.modal_page_counter_li_circle_active__2jyZu {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: rgb(122, 0, 0);
}

.modal_modal_topbar_arrowLeft_box__1J4SR {
  width: 10%;
  height: 100%;
  position: relative;
}

.modal_modal_topbar_content_wrapper__2gdJr {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.modal_modal_topbar_content_category__2Qke5 {
  width: 100%;
  height: 10%;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  color: #a067a1;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modal_modal_topbar_content_title__3NKhh {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: bold;
  color: #818181;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modal_modal_topbar_content_box__3P99V {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  font-size: 32px;
  font-weight: bold;
  color: #818181;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
}

.modal_modal_topbar_arrowRight_box__2CKZL {
  width: 10%;
  height: 100%;
  position: relative;
}

.modal_arrow_box_left__3I1mA {
  width: 50px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 40%;
  cursor: pointer;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.modal_arrow_box_right__2Kjkn {
  width: 50px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 40%;
  cursor: pointer;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.modal_arrow_box_right__2Kjkn .modal_arrow_right__2gVcW {
  width: 70px;
  height: 5px;
  background-color: rgb(202, 202, 202);
  position: absolute;
  left: 23px;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

.modal_arrow_box_right__2Kjkn .modal_arrow_left__1il3k {
  width: 70px;
  height: 5px;
  background-color: rgb(202, 202, 202);
  position: absolute;
  right: 20px;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
}

.modal_arrow_box_right__2Kjkn:hover .modal_arrow_left__1il3k {
  height: 7px;
  background-color: rgb(143, 143, 143);
}
.modal_arrow_box_right__2Kjkn:hover .modal_arrow_right__2gVcW {
  height: 7px;
  background-color: rgb(143, 143, 143);
}

.modal_arrow_box_left__3I1mA .modal_arrow_right__2gVcW {
  width: 70px;
  height: 5px;
  background-color: rgb(202, 202, 202);
  position: absolute;
  left: 23px;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

.modal_arrow_box_left__3I1mA .modal_arrow_left__1il3k {
  width: 70px;
  height: 5px;
  background-color: rgb(202, 202, 202);
  position: absolute;
  right: 20px;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
}

.modal_arrow_box_left__3I1mA:hover .modal_arrow_left__1il3k {
  height: 7px;
  background-color: rgb(143, 143, 143);
}
.modal_arrow_box_left__3I1mA:hover .modal_arrow_right__2gVcW {
  height: 7px;
  background-color: rgb(143, 143, 143);
}

.modal_correct_img__1N_e0 {
  object-fit: contain;
}

/* .delivery_cost_calc_box{
    width:300px;
    height:500px; 
    position: relative;
    position:absolute;
    top:0%;
    left:0%;
} */

.modal_delivery_cost_calc_box_iframe__2ArjG {
  width: 100%;
  height: 500px;
  position: absolute;
  top: 0%;
  left: 0%;
}

/* CSS for table "Стоимость доставки" content */
.modal_delivery_cost_table__1phxA {
  width: 50%;
  background: rgb(255, 255, 255);
  color: #444444;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  border-spacing: 1px;
  border-collapse: collapse;
  border: 2px solid;
}
.modal_delivery_cost_table_th__IZjzI {
  background-color: rgb(254, 176, 255);
  border: 2px solid;
}

.modal_delivery_cost_table_td__3BYwF {
  padding: 5px; /* Поля вокруг текста */
  border: 1px solid;
}
.modal_delivery_cost_table_th__IZjzI {
  padding: 5px; /* Поля вокруг текста */
  border: 1px solid;
}

.modal_mystyle__3Q-IE {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/* @font-face {
    font-family:'Monotype Corsiva';
    src: url('/assets/fonts/Monotype_Corsiva.ttf') format('ttf');
    font-weight: normal;
  font-style: normal;
  }
 */

@font-face {
  font-family: "Monotype Corsiva";
  src: url(/static/media/monotype-corsiva.0205fc94.eot); /* IE9 Compat Modes */
  src: url(/static/media/monotype-corsiva.0205fc94.eot?#iefix)
      format("embedded-opentype"),
     url(/static/media/monotype-corsiva.3515e95a.otf) format("opentype"),
     url(/static/media/monotype-corsiva.475e4265.svg)
      format("svg"),
     url(/static/media/monotype-corsiva.6fb60430.ttf)
      format("truetype"),
     url(/static/media/monotype-corsiva.e5c6da7e.woff)
      format("woff"),
     url(/static/media/monotype-corsiva.4fa01c0a.woff2)
      format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

.info_arrow_box_up__wLRIm {
  width: 50px;
  height: 20px;
  position: relative;
  margin: auto auto;
  cursor: pointer;
}

.info_arrow_box_up__wLRIm .info_arrow_left__1So56 {
  width: 70px;
  height: 5px;
  background-color: rgb(202, 202, 202);
  position: absolute;
  right: 20px;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
}

.info_arrow_box_up__wLRIm .info_arrow_right__3gWzG {
  width: 70px;
  height: 5px;
  background-color: rgb(202, 202, 202);
  position: absolute;
  left: 23px;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

.info_arrow_box_up__wLRIm:hover .info_arrow_left__1So56 {
  height: 7px;
  background-color: rgb(143, 143, 143);
}
.info_arrow_box_up__wLRIm:hover .info_arrow_right__3gWzG {
  height: 7px;
  background-color: rgb(143, 143, 143);
}

.info_arrow_box_dawn__8TQ2V {
  width: 50px;
  height: 20px;
  position: relative;
  margin: auto auto;
  margin-bottom: 60px;
  cursor: pointer;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.info_arrow_box_dawn__8TQ2V .info_arrow_left__1So56 {
  width: 70px;
  height: 5px;
  background-color: rgb(202, 202, 202);
  position: absolute;
  right: 22px;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
}

.info_arrow_box_dawn__8TQ2V .info_arrow_right__3gWzG {
  width: 70px;
  height: 5px;
  background-color: rgb(202, 202, 202);
  position: absolute;
  right: -41px;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

.info_arrow_box_dawn__8TQ2V:hover .info_arrow_left__1So56 {
  height: 7px;
  background-color: rgb(143, 143, 143);
}
.info_arrow_box_dawn__8TQ2V:hover .info_arrow_right__3gWzG {
  height: 7px;
  background-color: rgb(143, 143, 143);
}

.info_web-working-img-container__1YxRL {
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 22% 22% 22% 22%;
  grid-template-rows: 47% 47%;
  grid-template-areas:
    "slide1 slide2 slide3 slide4 "
    "slide5 slide6 slide7 slide8";
  grid-gap: 20px;
}

.info_info_blocks_wrapper__2WmQQ {
  width: 100%;
  height: 330px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  /*     background-color: rgb(204, 255, 138); */
}

.info_info_block_box__3vPjK {
  width: 230px;
  height: 250px;
  background-color: #f4f4f4;
  border-radius: 20px;
}

.info_info_block_title__1MNyl {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-decoration: underline;
  color: #d500da;
}

.info_info_block_ul__16puY {
  list-style-type: none;
  line-height: 30px;
  margin: 0;
  padding: 0;
}

.info_info_block_li__1Tkkn {
  font-size: 14px;
  padding-left: 30px;
  cursor: pointer;
}

.info_info_block_li__1Tkkn:hover {
  font-weight: bold;
  background-color: #fff;
  color: #d500da;
}

/* Styles for reviews section */
.info_reviews_wrapper__3_gWS {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.info_reviews_content_box__1VpVD {
  width: 90%;
  height: 90%;
}
.info_reviews_content_box_ul__2xSuI {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-around;
  padding: 0;
  margin: 3% 0 0 0;
}

.info_reviews_content_box_li__3uI8C {
  width: 30%;
  height: 90%;
  box-sizing: border-box;
  padding: 10px;
  margin: 0;
  border-radius: 5px;
  list-style-type: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #f4f4f4;
}

.info_reviews_content_box_li_name__21QVS {
  font-size: 16px;
}

.info_reviews_content_box_li_rating__1FLDK {
  height: 10%;
}

.info_reviews_content_box_li_review__34VmY {
  width: 100%;
  height: 60%;
  overflow-y: auto;
}

.info_reviews_content_box_li_review_date__XaA25 {
  height: 15%;
  display: flex;
  align-items: center;
}

.info_reviews_pagination_box__2RSO0 {
  width: 90%;
  height: 10%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.info_reviews_current_page_review_box__2X0tl {
  width: 15%;
  text-align: center;
}
.info_reviews_current_page_review__2Sliv {
  font-size: 14px;
  font-weight: bold;
  color: #d500da;
}

.info_reviews_svg_wrapper__1zr23 {
  width: 10%;
  height: 60%;
  text-align: center;
}

.info_reviews_svg_box__3AWUl {
  width: 80%;
  height: 100%;
  cursor: pointer;
  -webkit-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}
.info_rotate__1l7Az {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.info_reviews_svg_box__3AWUl:hover {
  -webkit-filter: none;
          filter: none;
}
.info_reviews_svg_arrow_right__1yf9K {
  fill: #8a8a8a;
}

.info_reviews_svg_box__3AWUl:hover .info_reviews_svg_arrow_right__1yf9K {
  fill: #a067a1;
}

.info_reviews_svg_box__3AWUl:hover .info_reviews_svg_arrow_left__2cY5G {
  fill: #a067a1;
}

.info_contacts_wrapper__2hSaY {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  outline: 1px solid #a067a1;
  outline-offset: -1px; /* Выводим рамку внутри элемента */
}

.info_contacts_top_box__2Fj8A {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
}

.info_contacts_top_info__2g3wZ {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.info_contacts_top_logo__ATHoh {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info_contacts_top_img__3unHe {
  height: 70%;
  cursor: pointer;
}

.info_contacts_top_url__1Gg1w {
  font-family: "Monotype Corsiva";
  font-size: 2.5vw;
  color: #d500da;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}
.info_contacts_nav_img_css__w1yDT {
  width: 40%;
  height: 100%;
  color: #a067a1;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info_contacts_top_description__zhCAb {
  width: 60%;
  height: 100%;
  font-family: Monotype Corsiva;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.info_contacts_top_descript_title__1JwJJ {
  font-size: 4.5vw;
  color: #d500da;
  margin-top: 2vw;
}

.info_contacts_top_descript_ul_box__1Vokg {
  width: 100%;
}

.info_contacts_top_descript_ul__2YS_W {
  display: inline-block;
  font-size: 2vw;
  margin-top: 2vw;
  color: #a067a1;
  line-height: 3vw;
  text-align: left;
  list-style-type: none;
  padding: 0;
}

.info_contacts_nav_css__kXbCk {
  color: #a067a1;
  text-decoration: none;
}

.info_contacts_top_footer__1hYKv {
  width: 100%;
  height: 30%;
  font-family: Monotype Corsiva;
  font-size: 50px;
  color: #d500da;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.info_contacts_top_footer_button__nQI9p {
  width: 15vw;
  height: 2.5vw;
  font-size: 1vw;
  font-size: 1.7vw;
  font-family: Monotype Corsiva;
  font-weight: bold;
  color: #d500da;
  background-color: #fffb99;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  transition-duration: 1s;
}

.info_contacts_top_footer_button__nQI9p:hover {
  color: #fffb99;
  background-color: #d500da;
}

.info_contacts_footer_box__2bv7Z {
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: #d500da;
}

.info_contacts_footer_instagram__2OPyi {
  width: 50%;
  height: 100%;
  font-family: Monotype Corsiva;
  font-size: 2.5vw;
  color: rgb(206, 206, 206);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.info_contacts_footer_mail__2EtlJ {
  width: 50%;
  height: 100%;
  font-family: Monotype Corsiva;
  font-size: 2.5vw;
  color: rgb(206, 206, 206);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.info_contacts_footer_mail_link__31-5f {
  text-decoration: none;
  color: rgb(206, 206, 206);
}

.info_contacts_cursor__226iq {
  cursor: pointer;
}

/* Cooperation form css */
.info_cooperation_title__1RNdm {
  width: 100%;
  color: #d500da;
  font-size: 2vw;
  font-weight: bold;
  text-align: center;
}
.info_cooperation_form__35-h9 {
  width: 100%;
  height: 100%;
}

.info_cooperation_form_question__2ogEX {
  color: #9932cc;
  display: block;
  text-align: left;
}

.info_cooperation_form_onlinestore_box__BZ-L8 {
  width: 100%;
  height: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 4%;
}

.info_cooperation_form_onlinestore_input__1Q8Zd {
  width: 45%;
  height: 22px;
  border: none;
  outline: none;
  border-bottom: 2px solid #8a8a8a;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  color: #9932cc;
  background-color: transparent;
  margin-top: 2%;
  text-indent: 1vw;
}

.info_cooperation_form_label_text__3sJlf {
  margin-left: 3px;
  vertical-align: -0.15em;
}

.info_cooperation_form_cooperation_textarea_box__3NW0V {
  width: 100%;
  height: 5vw;
  text-align: center;
  margin-top: 4%;
}

.info_cooperation_form_cooperation_textarea__2Eu44 {
  width: 80%;
  box-sizing: border-box;
  height: 3vw;
  resize: none;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #9932cc;
  border: thin dotted rgb(0, 0, 0);
  outline: none;
}

.info_cooperation_form_about_textarea_box__V3Pmo {
  width: 100%;
  height: 10vw;
  text-align: center;
  margin-top: 2%;
}

.info_cooperation_form_about_textarea__3P8zO {
  width: 80%;
  box-sizing: border-box;
  height: 8vw;
  resize: none;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #9932cc;
  border: thin dotted rgb(0, 0, 0);
  outline: none;
}

.info_cooperation_form_contacts_box__3angX {
  width: 100%;
  height: 10vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.info_cooperation_form_contacts_first_block__2wwLt {
  width: 50%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* background-color: rgb(247, 111, 111); */
}

.info_cooperation_form_contacts_second_block__2UnXs {
  width: 50%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.info_cooperation_form_contacts_input__3Ol0Y {
  width: 60%;
  height: 22px;
  border: none;
  outline: none;
  border-bottom: 2px solid #8a8a8a;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  color: #9932cc;
  background-color: transparent;
  margin-top: 2%;
}

.info_cooperation_form_button_box__3C9ub {
  width: 50%;
  height: 5vw;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto auto;
}

.info_cooperation_form_button_center__2SiFy {
  width: 50%;
  text-align: center;
}

.info_cooperation_form_button_back__3lVlo {
  width: 10vw;
  height: 2.5vw;
  font-size: 1vw;
  font-size: 1.7vw;
  font-family: Monotype Corsiva;
  font-weight: bold;
  color: #d500da;
  background-color: #fffb99;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  transition-duration: 1s;
}

.info_cooperation_form_button_back__3lVlo:hover {
  color: #fffb99;
  background-color: #d500da;
}

.info_cooperation_form_button_send__1oGI8 {
  width: 10vw;
  height: 2.5vw;
  font-size: 1vw;
  font-size: 1.7vw;
  font-family: Monotype Corsiva;
  font-weight: bold;
  color: darkgreen;
  background-color: #fffb99;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  transition-duration: 1s;
}

.info_cooperation_form_button_send__1oGI8:hover {
  color: #fffb99;
  background-color: darkgreen;
}

.info_cooperation_underline__1VHv0 {
  height: 1px;
  color: #d500da;
  background-color: #d500da;
}

.info_cooperation_underline_txt__2fVl3 {
  width: 100%;
  text-align: center;
}

/* Styles for title */
.share_block_title_box__30j6w {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.share_someClass__3gpb2 {
  font-size: 30px;
}

.share_block_title__2IywB {
  width: 250px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin: 50px 0 50px 0;
}

.share_mob_block_title__1JSpX {
  width: 250px;
  /* height: 13vw; */
  font-size: 3.5vw;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin: 50px 0 50px 0;
}

.share_block_title_line_left_box__1LkKW {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  margin-right: 10px;
}

.share_block_title_line_right_box__1Dmgt {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
}

.share_block_title_line_left_up__1IoRn {
  width: 90px;
  height: 3px;
  background-color: black;
  margin-bottom: 12px;
}

.share_block_title_line_left_dawn__8lotW {
  width: 45px;
  height: 3px;
  background-color: #d500da;
}

.share_block_title_line_right_up__V6MVm {
  width: 90px;
  height: 3px;
  background-color: #d500da;
  margin-bottom: 12px;
}

.share_block_title_line_right_dawn__zG6pU {
  width: 45px;
  height: 3px;
  background-color: black;
}

/* Styles for item Card */
.share_cards_wrapper__1bMyo {
  width: 95%;
  margin: auto auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.share_mob_cards_wrapper__1dpPa {
  width: 100%;
  margin: auto auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
}

.share_card_box__7q2Q9 {
  width: 33.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.share_mob_card_box__jT15k {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.share_card_wrapper__3E-v7 {
  width: 300px;
  height: 430px;
  border-radius: 10px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.share_mob_card_wrapper__15eu2 {
  width: 80%;
  height: 110vw;
  border-radius: 10px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  border: 2px solid #727272;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.share_card_wrapper__3E-v7:hover {
  box-sizing: border-box;
  border: 2px solid #727272;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.share_card_img__H4kC4 {
  width: 150px;
  height: 230px;
  object-fit: cover;
  border-radius: 10px;
  cursor: zoom-in;
}

.share_mob_card_img__11ewe {
  width: 80%;
  height: 60%;
  object-fit: cover;
  border-radius: 10px;
  cursor: zoom-in;
}

.share_card_img__H4kC4:active {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: fixed;
  top: 0%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 0px;
  background-color: rgba(209, 0, 218, 0.7);
  z-index: 9993;
}

.share_card_title__1wFAG {
  font-size: 22px;
  font-weight: bold;
  color: #818181;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.share_mob_card_title__1o30p {
  font-size: 5vw;
  font-weight: bold;
  color: #818181;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.share_card_price_box__3mbaI {
  width: 120px;
  height: 40px;
  border: 2px solid #818181;
  border-radius: 25px;
  color: #818181;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
}

.share_mob_card_price_box__4WSLB {
  width: 20vw;
  height: 7vw;
  border: 2px solid #818181;
  border-radius: 25px;
  color: #818181;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
}

.share_flashing__2kvi4 {
  -webkit-animation: share_flashing__2kvi4 2s infinite;
          animation: share_flashing__2kvi4 2s infinite;
  background-position: bottom left;
  background-size: 50px;
}

@-webkit-keyframes share_flashing__2kvi4 {
  20% {
    border-color: rgba(209, 0, 218, 0.5);
    box-shadow: 0px 0px 20px rgba(209, 0, 218, 0.5);
  }
}

@keyframes share_flashing__2kvi4 {
  20% {
    border-color: rgba(209, 0, 218, 0.5);
    box-shadow: 0px 0px 20px rgba(209, 0, 218, 0.5);
  }
}

.share_card_sale_price__r3f2f {
  width: 120px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  -webkit-animation: share_type__3qYp9 5s steps(100, end) infinite;
          animation: share_type__3qYp9 5s steps(100, end) infinite;
}
@-webkit-keyframes share_type__3qYp9 {
  from {
    width: 0;
  }
}
@keyframes share_type__3qYp9 {
  from {
    width: 0;
  }
}
.share_mob_card_sale_price__2dsEP {
  width: 15vw;
  font-size: 3vw;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  -webkit-animation: share_type__3qYp9 5s steps(100, end) infinite;
          animation: share_type__3qYp9 5s steps(100, end) infinite;
}
@keyframes share_type__3qYp9 {
  from {
    width: 0;
  }
}

.share_card_price_with_sale__2D1fO {
  font-size: 12px;
  text-decoration: line-through;
  margin: 0;
  padding: 0;
}

.share_mob_card_price_with_sale__3fP0D {
  text-decoration: line-through;
  margin: 0;
  padding: 0;
}

.share_mob_price__27izT {
  font-size: 3vw;
}

.share_card_sale_img_present__2VdLm {
  width: 30%;
  height: 60%;
  top: 0%;
  left: 70%;
  position: absolute;
}

.share_card_stars_rating__2EzSV {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.share_card_btn_box__1aVfq {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}
.share_mob_card_btn_box__Qjd9h {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.share_card_btn__3w6vR {
  width: 40%;
  height: 35px;
  font-size: 18px;
  font-weight: bold;
  color: #a067a1;
  text-transform: uppercase;
  background: #b8f5b8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.share_mob_card_btn__hFj9b {
  width: 40%;
  height: 9vw;
  font-size: 4vw;
  font-weight: bold;
  color: #a067a1;
  text-transform: uppercase;
  background: #b8f5b8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.share_card_btn_active__21b5B {
  background: #fffb99;
}

.share_card_btn__3w6vR:hover {
  background: #fffb99;
}

/* Стили для пагинации */
.share_page_counter_ul__27pBN {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: #a067a1;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

/* .mob_page_counter_ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: #a067a1;
  font-size: 6vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
} */

.share_page_counter_li__7DDYJ {
  width: 20px;
  cursor: pointer;
}

.share_page_counter_li__7DDYJ:hover {
  -webkit-transform: scale(1.03, 1.03);
          transform: scale(1.03, 1.03);
  text-decoration: underline;
  font-weight: bold;
  color: #d500da;
}

.share_page_counter_li_active__2bdIR {
  width: 20px;
  cursor: pointer;
  -webkit-transform: scale(1.03, 1.03);
          transform: scale(1.03, 1.03);
  text-decoration: underline;
  font-weight: bold;
  color: #d500da;
}

/* Pnotify style */
.share_someClass__3gpb2.share_ui-pnotify__2bOYP .share_brighttheme__2RrXj .share_ui-pnotify-text__20Afb {
  font-size: 10vw;
}

/* .ui-pnotify-text.someClass {
  font-family: Arial, Helvetica, sans-serif !important;
  text-shadow: 2px 2px 3px black !important;
  font-size: 10pt !important;
  color: #fff !important;
  padding-left: 50px !important;
  line-height: 1 !important;
  text-rendering: geometricPrecision !important;
}
[ui-pnotify].ui-pnotify .brighttheme .ui-pnotify-text .someClass {
  font-size: 10pt !important;
}
 */
.share_basket_cards_wrapper__2EPfR {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* background-color: rgb(255, 65, 65); */
}

.share_basket_empty_wrapper__2vlhq {
  width: 100%;
  height: 100%;
  font-size: 40px;
  font-weight: bold;
  color: rgb(182, 0, 0);
  display: flex;
  justify-content: center;
}

.share_total_order_line__21Iqq {
  width: 70%;
  height: 3px;
  background-color: rgb(141, 0, 0);
  margin-bottom: 20px;
}

.share_total_order_wrapper__2J5pi {
  width: 60%;
  font-size: 18px;
  font-weight: bold;
  text-align: right;
  white-space: pre;
  overflow: hidden;
  white-space: nowrap;
}

.share_total_order_fake_div__2Fk7X {
  width: 60%;
}

.share_order_buttons_wrapper__2U1mk {
  width: 30%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.share_order_button__1Prj6 {
  width: 40%;
  height: 40px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #b8f5b8;
  color: #a067a1;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.share_order_button__1Prj6:hover {
  box-shadow: none;
  background-color: #fffb99;
}

.share_order_button_active__3jyhD {
  background-color: #fffb99;
}

.share_order_phone_form__2KylP {
  margin: auto auto;
  width: 40%;
  height: 20vw;
  background-color: rgb(241, 241, 241);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: solid 1px white;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.share_footer_form_input__k3w8Q {
  width: 200px;
  height: 22px;
  border: none;
  outline: none;
  border-bottom: 2px solid #8a8a8a;
  margin-bottom: 40px;
  text-align: left;
  font-size: 18px;
  line-height: 22px;
  color: #9932cc;
  background-color: transparent;
}
.share_order_form_send_btn_box__3mQTB {
  width: 100%;
  height: 13%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.share_order_form_send_btn__3yaHy {
  width: 30%;
  height: 80%;
  background-color: #b8f5b8;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #a067a1;
  border: none;
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
}

.share_order_form_send_btn__3yaHy:hover {
  box-shadow: none;
  background-color: #fffb99;
}
/* new */
.share_order_checkout_wrapper__38sAV {
  margin: auto auto;
  width: 80%;
  height: 30vw;
  background-color: rgb(241, 241, 241);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: solid 1px white;
}

.share_order_checkout_title_wrapper__3oAX1 {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: auto auto;
  margin-top: 1%;
  /* background-color: rgb(70, 45, 214); */
}

.share_order_checkout_section_title__i4KpV {
  width: 33.3%;
  color: #d500da;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
}

.share_order_checkout_content_wrapper__2RW26 {
  width: 100%;
  height: 90%;
  margin: auto auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(206, 29, 29); */
}

.share_order_checkout_content_section_box__2P20d {
  width: 33.3%;
  height: 100%;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* background-color: #fff; */
}

.share_order_checkout_content_section_box_sharedata__10Gdw {
  width: 90%;
  height: 45%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* background-color: rgb(245, 123, 123); */
}

.share_order_checkout_content_section_box_delivery__32VeR {
  width: 90%;
  height: 45%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* background-color: rgb(133, 245, 123);  */
}

.share_order_checkout_content_section_box_delivery_googleMap__3pryx {
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: red;
  font-size: 14px;
  cursor: pointer;
  /*  background-color: rgb(88, 219, 71); */
}

.share_order_checkout_content_section_box_delivery_np_img__33cQ2 {
  width: 10%;
}

.share_order_checkout_share_box__3LK5p {
  width: 30%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.share_order_checkout_share_box_onlytaro__1hJa9 {
  width: 100%;
  height: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.share_order_checkout_share_input__34iWL {
  width: 75%;
  height: 22px;
  border: none;
  outline: none;
  border-bottom: 2px solid #8a8a8a;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  color: #9932cc;
  background-color: transparent;
  position: relative;
}

.share_order_checkout_share_input_disactive__19arw {
  -webkit-animation: share_border__22M8t 1s cubic-bezier(1, 0, 0, 1) infinite;
          animation: share_border__22M8t 1s cubic-bezier(1, 0, 0, 1) infinite;
}

@-webkit-keyframes share_border__22M8t {
  100% {
    border-bottom-color: rgb(141, 0, 0);
  }
}

@keyframes share_border__22M8t {
  100% {
    border-bottom-color: rgb(141, 0, 0);
  }
}

.share_order_checkout_share_input_city_lang__16vz4 {
  width: 70%;
  font-weight: bold;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 110%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  margin-top: 0%;
}

.share_order_checkout_share_input_city_lang_rus_box__4GhD2 {
  width: 50%;
  height: 20px;
  text-align: center;
}

.share_order_checkout_share_input_city_lang_ukr_box__3Y4Qm {
  width: 50%;
  height: 20px;
  text-align: center;
}

.share_order_checkout_share_input_city_lang_img__1_jau {
  width: auto;
  height: 100%;
  cursor: pointer;
  margin: auto auto;
  opacity: 0.4;
}

.share_order_checkout_share_input_city_lang_active__1xgkr {
  width: auto;
  height: 100%;
  cursor: pointer;
  margin: auto auto;
  position: relative;
}

.share_order_checkout_payment_wrapper__3Vb1u {
  width: 98%;
  height: 40%;
  border: 2px dotted #d500da;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  white-space: nowrap;
  font-size: 12px;
  position: relative;
}

.share_order_checkout_radio_box_payment_title__3hQR1 {
  width: 65%;
  display: block;
  position: absolute;
  top: -8%;
  left: 0;
  color: #d500da;
  font-size: 13px;
  font-weight: bold;
  background-color: #f1f1f1;
  text-transform: uppercase;
  text-align: left;
  text-indent: 10%;
}

.share_order_checkout_radio_box_clarify_title__dlWBE {
  width: 65%;
  display: block;
  position: absolute;
  top: -9%;
  left: 0;
  color: #d500da;
  font-size: 13px;
  font-weight: bold;
  background-color: #f1f1f1;
  text-transform: uppercase;
  text-align: left;
  text-indent: 10%;
}

label {
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  text-align: left;
}

.share_order_checkout_clarify_wrapper__3w5AP {
  width: 100%;
  height: 40%;
  border: 2px dotted #d500da;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  white-space: nowrap;
  position: relative;
  font-size: 12px;
}

.share_order_checkout_clarify_input__3eSyq {
  margin-left: 3px;
  vertical-align: -0.15em;
}

.share_order_checkout_add_box__v56NS {
  width: 30%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.share_order_checkout_add_textarea_box__5NZBG {
  width: 100%;
  height: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(168, 56, 56); */
}

.share_order_checkout_add_textarea__kiuuT {
  width: 80%;
  box-sizing: border-box;
  height: 90%;
  resize: none;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #8a8a8a;
  border: none;
  outline: none;
}

.share_order_form_send_btn_checkout__3urpg {
  width: 60%;
  height: 80%;
  background-color: #b8f5b8;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #a067a1;
  border: none;
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
}

.share_order_form_send_btn_checkout__3urpg:hover {
  box-shadow: none;
  background-color: #fffb99;
}

.share_define_order_title__2BAQp {
  margin-bottom: 1%;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: #818181;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.share_request_order_equipment_box__3We-S {
  width: 60%;
  height: 70%;
  background-color: rgb(241, 241, 241);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: solid 1px white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  /* background-color: rgb(224, 60, 60); */
}

.share_request_order_equipment_currentorder_box__26-Lb {
  width: 45%;
  height: 90%;
  position: relative;
  background-color: #fffb99;
}

.share_request_order_equipment_currentorder_box_btn__3kIEI {
  position: absolute;
  width: 130px;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  top: 85%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #818181;
}

.share_request_order_equipment_currentorder_box_btn__3kIEI:hover {
  background-color: #a067a1;
}

.share_request_order_equipment_basketorder_box__2W-zM {
  width: 45%;
  height: 90%;
  position: relative;
  background-color: #b8f5b8;
}

.share_request_order_equipment_ul_title__3IMo3 {
  font-size: 18px;
  color: rgb(149, 0, 130);
  text-decoration: underline;
  font-weight: bold;
  text-align: center;
  text-shadow: 0.3px 0.3px 0px grey;
}

.share_request_order_equipment_ul__2DVvO {
  font-size: 16px;
  color: rgb(149, 0, 130);
  text-shadow: 0.3px 0.3px 0px grey;
  font-weight: normal;
  position: relative;
  list-style: decimal-leading-zero;
  height: 50%;
}

.share_request_order_equipment_ul_vert_scroll__3O13C {
  overflow-y: auto;
}

.share_request_order_equipment_basketorder_box_btn__1SyRP {
  position: absolute;
  width: 130px;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  top: 85%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #818181;
}

.share_request_order_equipment_basketorder_box_btn__1SyRP:hover {
  background-color: #a067a1;
}

.share_order_checkout_share_input_NP_box__1F5lJ {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.share_order_checkout_np_city_list__G-ZvB {
  background: #fffb99;
  color: rgb(78, 78, 78);
  position: absolute;
  top: 100%;
  width: 75%;
  height: 100px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  overflow-y: scroll;
  line-height: 20px;
  z-index: 9999;
  text-align: left;
}

.share_order_checkout_np_city_list_notfound__2wU0L {
  color: rgb(141, 0, 0);
  text-align: center;
}

.share_google_map_box__352Co {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: #a067a1;
  opacity: 1;

  /*     width:100%;
    height:100vh;
    position:absolute;
    z-index:10000;
    background-color: red; */
}
.share_google_map_box__352Co iframe {
  left: 50%;
  top: 50%;
  height: 90%;
  width: 90%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.footer_footer_socials_box__1X8zh {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
}

.footer_footer_socials_box_margin__2u3ut {
  margin-right: 100px;
  margin-bottom: 15px;
}

.footer_footer_form_callback__32zAW {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-right: 10px;
}

.footer_footer_form_input_box__2A-Cn {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
}

.footer_footer_form_input__1U4vO {
  width: 80%;
  height: 1.7vw;
  border: none;
  outline: none;
  border-bottom: 2px solid #8a8a8a;
  margin-top: 20px;
  text-align: center;
  font-size: 22px;
  line-height: 22px;
  color: #9932cc;
}

.footer_footer_form_input_notValid__2nG-x {
  border-bottom: 2px solid rgb(182, 0, 0);
}

.footer_footer_form_input__1U4vO::-webkit-input-placeholder {
  color: rgb(189, 189, 189);
  font-size: 18px;
}

.footer_footer_form_input__1U4vO::placeholder {
  color: rgb(189, 189, 189);
  font-size: 18px;
}

.footer_footer_input_subtitle__3Ezk9 {
  margin: 0;
  padding: 0;
  color: #8a8a8a;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.footer_footer_socials_circle__349Nw {
  width: 3.5vw;
  height: 3.5vw;
  margin-right: 3vw;
}

.footer_footer_socials_circle_mob__2WtzZ {
  width: 13vw;
  height: 13vw;
  /* margin-right: 3vw; */
}

/* .footer_socials_circle:nth-child(3n){
    margin-right: 0;
} */

.footer_footer_socials_form_btn__h-eqD {
  border: none;
  outline: none;
  background-color: transparent;
}

.footer_footer_socials_svg_box__K7rb_ {
  width: 3.5vw;
  height: 3.5vw;
}

.footer_footer_socials_svg_box_mob__dkaT0 {
  width: 13vw;
  height: 13vw;
}

.footer_footer_socials_svg__1eVZu {
  fill: #8a8a8a;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform-origin: 50% 50% 0;
  transform-origin: 50% 50% 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  cursor: pointer;
}

.footer_footer_socials_circle__349Nw:hover .footer_footer_socials_svg__1eVZu {
  fill: #9932cc;
  -webkit-transition: transform 0.5s;
  -webkit-transform-origin: 50% 50% 0;
  -webkit-transform: rotate(360deg);
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  transform-origin: 50% 50% 0;
  transform: rotate(360deg);
}

@font-face {
  font-family: "Monotype Corsiva";
  src: url("/assets/fonts/Monotype_Corsiva.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

.header_header_wrapper__3o6t3 {
  width: 100%;
  height: 15vw;
  background-color: #d500da;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  margin-top: -15vw;
}

.header_header_wrapper_menu__348Pt {
  width: 33.3%;
}

.header_burger__EXLEZ {
  margin-left: 4em;
}

.header_dropdown_box__3JaUS {
  font-size: 4vw;
}

.header_dropdown_title__mO8Qp {
  width: 100%;
  font-family: "Monotype Corsiva";
  font-size: 10vw;
  color: #a067a1;
  text-align: center;
  margin-top: 2vw;
}

.header_menu_item__3KBqc {
  display: block;
  padding: 1.7rem 15px;
  text-decoration: none;
  border-bottom: 1px solid #ddd;
  color: rgb(123, 123, 123);
  font-weight: bold;
  list-style-type: none;
}

.header_menu_item__3KBqc::first-letter {
  text-transform: capitalize;
}

.header_contacts_box__EBc-U {
  width: 100%;
  height: 38vw;
  color: #a067a1;
  /*   background-color: rgb(71, 59, 59); */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header_contacts_footer_div__1vHjV {
  width: 100%;
  height: 10vw;
  background-color: #d500da;
}

.header_dropdawn_tagline__73gxy {
  width: 90%;
  font-size: 4vw;
  text-align: center;
  color: rgb(149, 0, 130);
  text-shadow: 0.3px 0.3px 0px grey;
  margin: auto auto;
}

.header_header_wrapper_logo__1I0rt {
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.header_logo_img__8cFI5 {
  width: 15vw;
  height: 15vw;
}

.header_header_wrapper_basket__1Kbmc {
  width: 33.3%;
  height: 70%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-end;
  color: #efefef;
  font-size: 3.2vw;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.gallery_content_gallery_box__3P35Q {
  width: 95%;
  height: 46%;
  overflow: hidden;
  margin: auto auto;
}

.gallery_content_gallery_title__3Q4rv {
  width: 100%;
  height: 13vw;
  font-size: 1.9vw;
}

.gallery_content_gallery_img__3-5xA {
  width: 100%;
  height: 120vw;
  border-radius: 1vw;
}

.gallery_content_gallery_box_spinner__6Or3B {
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.service_content_service_box__2pUt- {
  /*  padding-top: 15vw; */
  width: 95%;
  height: 45%;
  overflow: hidden;
  margin: auto auto;

  /* background-color: rgb(243, 125, 125); */
}

.service_content_service_img__2PevV {
  width: 100%;
  height: 120vw;
  border-radius: 1vw;
}

.service_content_service_box_spinner__GySzc {
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

