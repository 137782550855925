@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

body {
  padding: 0;
  margin: 0;
  -ms-user-select: auto;
  -moz-user-select: auto;
  -webkit-user-select: auto;
  user-select: auto;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
}

.order_checkout_np_list_spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.wrapper {
  margin: 10px;
  font-family: "Roboto", sans-serif;
  position: relative;
}

.notFound_wrapper {
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  color: rgb(182, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mob_wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
}

.mob_content_box {
  margin-top: 15vw;
}

.mob_footer_box {
  width: 100%;
  height: 20vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: relative;
}
