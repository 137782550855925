@font-face {
  font-family: "Monotype Corsiva";
  src: url("/assets/fonts/Monotype_Corsiva.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

.header_wrapper {
  width: 100%;
  height: 15vw;
  background-color: #d500da;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  margin-top: -15vw;
}

.header_wrapper_menu {
  width: 33.3%;
}

.burger {
  margin-left: 4em;
}

.dropdown_box {
  font-size: 4vw;
}

.dropdown_title {
  width: 100%;
  font-family: "Monotype Corsiva";
  font-size: 10vw;
  color: #a067a1;
  text-align: center;
  margin-top: 2vw;
}

.menu_item {
  display: block;
  padding: 1.7rem 15px;
  text-decoration: none;
  border-bottom: 1px solid #ddd;
  color: rgb(123, 123, 123);
  font-weight: bold;
  list-style-type: none;
}

.menu_item::first-letter {
  text-transform: capitalize;
}

.contacts_box {
  width: 100%;
  height: 38vw;
  color: #a067a1;
  /*   background-color: rgb(71, 59, 59); */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contacts_footer_div {
  width: 100%;
  height: 10vw;
  background-color: #d500da;
}

.dropdawn_tagline {
  width: 90%;
  font-size: 4vw;
  text-align: center;
  color: rgb(149, 0, 130);
  text-shadow: 0.3px 0.3px 0px grey;
  margin: auto auto;
}

.header_wrapper_logo {
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.logo_img {
  width: 15vw;
  height: 15vw;
}

.header_wrapper_basket {
  width: 33.3%;
  height: 70%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-end;
  color: #efefef;
  font-size: 3.2vw;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
