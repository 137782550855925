/* Styles for title */
.block_title_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.someClass {
  font-size: 30px;
}

.block_title {
  width: 250px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin: 50px 0 50px 0;
}

.mob_block_title {
  width: 250px;
  /* height: 13vw; */
  font-size: 3.5vw;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin: 50px 0 50px 0;
}

.block_title_line_left_box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  margin-right: 10px;
}

.block_title_line_right_box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
}

.block_title_line_left_up {
  width: 90px;
  height: 3px;
  background-color: black;
  margin-bottom: 12px;
}

.block_title_line_left_dawn {
  width: 45px;
  height: 3px;
  background-color: #d500da;
}

.block_title_line_right_up {
  width: 90px;
  height: 3px;
  background-color: #d500da;
  margin-bottom: 12px;
}

.block_title_line_right_dawn {
  width: 45px;
  height: 3px;
  background-color: black;
}

/* Styles for item Card */
.cards_wrapper {
  width: 95%;
  margin: auto auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.mob_cards_wrapper {
  width: 100%;
  margin: auto auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card_box {
  width: 33.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.mob_card_box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.card_wrapper {
  width: 300px;
  height: 430px;
  border-radius: 10px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.mob_card_wrapper {
  width: 80%;
  height: 110vw;
  border-radius: 10px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  border: 2px solid #727272;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.card_wrapper:hover {
  box-sizing: border-box;
  border: 2px solid #727272;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.card_img {
  width: 150px;
  height: 230px;
  object-fit: cover;
  border-radius: 10px;
  cursor: zoom-in;
}

.mob_card_img {
  width: 80%;
  height: 60%;
  object-fit: cover;
  border-radius: 10px;
  cursor: zoom-in;
}

.card_img:active {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: fixed;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0px;
  background-color: rgba(209, 0, 218, 0.7);
  z-index: 9993;
}

.card_title {
  font-size: 22px;
  font-weight: bold;
  color: #818181;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.mob_card_title {
  font-size: 5vw;
  font-weight: bold;
  color: #818181;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card_price_box {
  width: 120px;
  height: 40px;
  border: 2px solid #818181;
  border-radius: 25px;
  color: #818181;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
}

.mob_card_price_box {
  width: 20vw;
  height: 7vw;
  border: 2px solid #818181;
  border-radius: 25px;
  color: #818181;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
}

.flashing {
  animation: flashing 2s infinite;
  background-position: bottom left;
  background-size: 50px;
}

@keyframes flashing {
  20% {
    border-color: rgba(209, 0, 218, 0.5);
    box-shadow: 0px 0px 20px rgba(209, 0, 218, 0.5);
  }
}

.card_sale_price {
  width: 120px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  animation: type 5s steps(100, end) infinite;
}
@keyframes type {
  from {
    width: 0;
  }
}
.mob_card_sale_price {
  width: 15vw;
  font-size: 3vw;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  animation: type 5s steps(100, end) infinite;
}
@keyframes type {
  from {
    width: 0;
  }
}

.card_price_with_sale {
  font-size: 12px;
  text-decoration: line-through;
  margin: 0;
  padding: 0;
}

.mob_card_price_with_sale {
  text-decoration: line-through;
  margin: 0;
  padding: 0;
}

.mob_price {
  font-size: 3vw;
}

.card_sale_img_present {
  width: 30%;
  height: 60%;
  top: 0%;
  left: 70%;
  position: absolute;
}

.card_stars_rating {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card_btn_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}
.mob_card_btn_box {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.card_btn {
  width: 40%;
  height: 35px;
  font-size: 18px;
  font-weight: bold;
  color: #a067a1;
  text-transform: uppercase;
  background: #b8f5b8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.mob_card_btn {
  width: 40%;
  height: 9vw;
  font-size: 4vw;
  font-weight: bold;
  color: #a067a1;
  text-transform: uppercase;
  background: #b8f5b8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.card_btn_active {
  background: #fffb99;
}

.card_btn:hover {
  background: #fffb99;
}

/* Стили для пагинации */
.page_counter_ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: #a067a1;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

/* .mob_page_counter_ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: #a067a1;
  font-size: 6vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
} */

.page_counter_li {
  width: 20px;
  cursor: pointer;
}

.page_counter_li:hover {
  transform: scale(1.03, 1.03);
  text-decoration: underline;
  font-weight: bold;
  color: #d500da;
}

.page_counter_li_active {
  width: 20px;
  cursor: pointer;
  transform: scale(1.03, 1.03);
  text-decoration: underline;
  font-weight: bold;
  color: #d500da;
}

/* Pnotify style */
.someClass.ui-pnotify .brighttheme .ui-pnotify-text {
  font-size: 10vw;
}

/* .ui-pnotify-text.someClass {
  font-family: Arial, Helvetica, sans-serif !important;
  text-shadow: 2px 2px 3px black !important;
  font-size: 10pt !important;
  color: #fff !important;
  padding-left: 50px !important;
  line-height: 1 !important;
  text-rendering: geometricPrecision !important;
}
[ui-pnotify].ui-pnotify .brighttheme .ui-pnotify-text .someClass {
  font-size: 10pt !important;
}
 */
.basket_cards_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* background-color: rgb(255, 65, 65); */
}

.basket_empty_wrapper {
  width: 100%;
  height: 100%;
  font-size: 40px;
  font-weight: bold;
  color: rgb(182, 0, 0);
  display: flex;
  justify-content: center;
}

.total_order_line {
  width: 70%;
  height: 3px;
  background-color: rgb(141, 0, 0);
  margin-bottom: 20px;
}

.total_order_wrapper {
  width: 60%;
  font-size: 18px;
  font-weight: bold;
  text-align: right;
  white-space: pre;
  overflow: hidden;
  white-space: nowrap;
}

.total_order_fake_div {
  width: 60%;
}

.order_buttons_wrapper {
  width: 30%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.order_button {
  width: 40%;
  height: 40px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #b8f5b8;
  color: #a067a1;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.order_button:hover {
  box-shadow: none;
  background-color: #fffb99;
}

.order_button_active {
  background-color: #fffb99;
}

.order_phone_form {
  margin: auto auto;
  width: 40%;
  height: 20vw;
  background-color: rgb(241, 241, 241);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: solid 1px white;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer_form_input {
  width: 200px;
  height: 22px;
  border: none;
  outline: none;
  border-bottom: 2px solid #8a8a8a;
  margin-bottom: 40px;
  text-align: left;
  font-size: 18px;
  line-height: 22px;
  color: #9932cc;
  background-color: transparent;
}
.order_form_send_btn_box {
  width: 100%;
  height: 13%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.order_form_send_btn {
  width: 30%;
  height: 80%;
  background-color: #b8f5b8;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #a067a1;
  border: none;
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
}

.order_form_send_btn:hover {
  box-shadow: none;
  background-color: #fffb99;
}
/* new */
.order_checkout_wrapper {
  margin: auto auto;
  width: 80%;
  height: 30vw;
  background-color: rgb(241, 241, 241);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: solid 1px white;
}

.order_checkout_title_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: auto auto;
  margin-top: 1%;
  /* background-color: rgb(70, 45, 214); */
}

.order_checkout_section_title {
  width: 33.3%;
  color: #d500da;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
}

.order_checkout_content_wrapper {
  width: 100%;
  height: 90%;
  margin: auto auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(206, 29, 29); */
}

.order_checkout_content_section_box {
  width: 33.3%;
  height: 100%;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* background-color: #fff; */
}

.order_checkout_content_section_box_sharedata {
  width: 90%;
  height: 45%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* background-color: rgb(245, 123, 123); */
}

.order_checkout_content_section_box_delivery {
  width: 90%;
  height: 45%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* background-color: rgb(133, 245, 123);  */
}

.order_checkout_content_section_box_delivery_googleMap {
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: red;
  font-size: 14px;
  cursor: pointer;
  /*  background-color: rgb(88, 219, 71); */
}

.order_checkout_content_section_box_delivery_np_img {
  width: 10%;
}

.order_checkout_share_box {
  width: 30%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.order_checkout_share_box_onlytaro {
  width: 100%;
  height: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.order_checkout_share_input {
  width: 75%;
  height: 22px;
  border: none;
  outline: none;
  border-bottom: 2px solid #8a8a8a;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  color: #9932cc;
  background-color: transparent;
  position: relative;
}

.order_checkout_share_input_disactive {
  animation: border 1s cubic-bezier(1, 0, 0, 1) infinite;
}

@keyframes border {
  100% {
    border-bottom-color: rgb(141, 0, 0);
  }
}

.order_checkout_share_input_city_lang {
  width: 70%;
  font-weight: bold;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 110%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  margin-top: 0%;
}

.order_checkout_share_input_city_lang_rus_box {
  width: 50%;
  height: 20px;
  text-align: center;
}

.order_checkout_share_input_city_lang_ukr_box {
  width: 50%;
  height: 20px;
  text-align: center;
}

.order_checkout_share_input_city_lang_img {
  width: auto;
  height: 100%;
  cursor: pointer;
  margin: auto auto;
  opacity: 0.4;
}

.order_checkout_share_input_city_lang_active {
  width: auto;
  height: 100%;
  cursor: pointer;
  margin: auto auto;
  position: relative;
}

.order_checkout_payment_wrapper {
  width: 98%;
  height: 40%;
  border: 2px dotted #d500da;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  white-space: nowrap;
  font-size: 12px;
  position: relative;
}

.order_checkout_radio_box_payment_title {
  width: 65%;
  display: block;
  position: absolute;
  top: -8%;
  left: 0;
  color: #d500da;
  font-size: 13px;
  font-weight: bold;
  background-color: #f1f1f1;
  text-transform: uppercase;
  text-align: left;
  text-indent: 10%;
}

.order_checkout_radio_box_clarify_title {
  width: 65%;
  display: block;
  position: absolute;
  top: -9%;
  left: 0;
  color: #d500da;
  font-size: 13px;
  font-weight: bold;
  background-color: #f1f1f1;
  text-transform: uppercase;
  text-align: left;
  text-indent: 10%;
}

label {
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  text-align: left;
}

.order_checkout_clarify_wrapper {
  width: 100%;
  height: 40%;
  border: 2px dotted #d500da;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  white-space: nowrap;
  position: relative;
  font-size: 12px;
}

.order_checkout_clarify_input {
  margin-left: 3px;
  vertical-align: -0.15em;
}

.order_checkout_add_box {
  width: 30%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.order_checkout_add_textarea_box {
  width: 100%;
  height: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(168, 56, 56); */
}

.order_checkout_add_textarea {
  width: 80%;
  box-sizing: border-box;
  height: 90%;
  resize: none;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #8a8a8a;
  border: none;
  outline: none;
}

.order_form_send_btn_checkout {
  width: 60%;
  height: 80%;
  background-color: #b8f5b8;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #a067a1;
  border: none;
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
}

.order_form_send_btn_checkout:hover {
  box-shadow: none;
  background-color: #fffb99;
}

.define_order_title {
  margin-bottom: 1%;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: #818181;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.request_order_equipment_box {
  width: 60%;
  height: 70%;
  background-color: rgb(241, 241, 241);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: solid 1px white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  /* background-color: rgb(224, 60, 60); */
}

.request_order_equipment_currentorder_box {
  width: 45%;
  height: 90%;
  position: relative;
  background-color: #fffb99;
}

.request_order_equipment_currentorder_box_btn {
  position: absolute;
  width: 130px;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  top: 85%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #818181;
}

.request_order_equipment_currentorder_box_btn:hover {
  background-color: #a067a1;
}

.request_order_equipment_basketorder_box {
  width: 45%;
  height: 90%;
  position: relative;
  background-color: #b8f5b8;
}

.request_order_equipment_ul_title {
  font-size: 18px;
  color: rgb(149, 0, 130);
  text-decoration: underline;
  font-weight: bold;
  text-align: center;
  text-shadow: 0.3px 0.3px 0px grey;
}

.request_order_equipment_ul {
  font-size: 16px;
  color: rgb(149, 0, 130);
  text-shadow: 0.3px 0.3px 0px grey;
  font-weight: normal;
  position: relative;
  list-style: decimal-leading-zero;
  height: 50%;
}

.request_order_equipment_ul_vert_scroll {
  overflow-y: auto;
}

.request_order_equipment_basketorder_box_btn {
  position: absolute;
  width: 130px;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  top: 85%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #818181;
}

.request_order_equipment_basketorder_box_btn:hover {
  background-color: #a067a1;
}

.order_checkout_share_input_NP_box {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.order_checkout_np_city_list {
  background: #fffb99;
  color: rgb(78, 78, 78);
  position: absolute;
  top: 100%;
  width: 75%;
  height: 100px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  overflow-y: scroll;
  line-height: 20px;
  z-index: 9999;
  text-align: left;
}

.order_checkout_np_city_list_notfound {
  color: rgb(141, 0, 0);
  text-align: center;
}

.google_map_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: #a067a1;
  opacity: 1;

  /*     width:100%;
    height:100vh;
    position:absolute;
    z-index:10000;
    background-color: red; */
}
.google_map_box iframe {
  left: 50%;
  top: 50%;
  height: 90%;
  width: 90%;
  position: absolute;
  transform: translate(-50%, -50%);
}
