.modal_card_wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9001;
  background-color: rgb(0, 0, 0);
  opacity: 0.95;
}

.modal_card_container {
  width: 85vw;
  height: 45vw;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9992;
  box-sizing: border-box;
  border: 2px solid #727272;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.mob_modal_card_container {
  width: 85vw;
  height: 120vw;
  background-color: #fff;
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9992;
  box-sizing: border-box;
  border: 2px solid #727272;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.mob_modal_container_order {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(214, 68, 68); */
  position: absolute;
  top: 0vw;
  overflow: hidden;
  overflow: scroll;
}

.mob_modal_container_order_phone {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.modal_card_container_title_box {
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
}

.mob_modal_card_container_title_box {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 5vw;
  font-weight: bold;
  color: #818181;
  /*   overflow: auto !important;
  -webkit-overflow-scrolling: auto !important;
  -webkit-overflow-scrolling: touch !important; */

  /*   overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; */
  /* -webkit-overflow-scrolling-y: touch; */
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modal_card_container_title_txt_box {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 3vw;
  font-weight: bold;
  color: #818181;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.mob_modal_basket_card_header_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mob_modal_card_container_title_txt {
  width: 100%;
  font-size: 5vw;
  font-weight: bold;
  color: #818181;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modal_card_container_orders_title_txt {
  width: 100%;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #818181;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: sticky;
  top: 0;
}

.modal_card_container_btn_back_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 25px 0 0;
}
/* .mob_modal_card_container_btn_back_box {
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 25px 0 0;
}
 */
.modal_card_container_btn_back_txt {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #818181;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
}

.mob_modal_card_container_btn_back_txt {
  font-size: 4vw;
  font-weight: bold;
  text-align: center;
  color: #a067a1;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
}

.modal_card_container_btn_back_txt:hover {
  color: #a067a1;
  text-shadow: none;
}

.modal_price_box {
  width: 40%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}
.mob_modal_price_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.modal_basket_card_counter_box {
  width: 10%;
  height: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.mob_modal_basket_card_counter_box {
  width: 20%;
  height: 8vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.basket_card_counter {
  width: 100%;
  height: 20px;
  outline: none;
  box-shadow: 1px 1px 2px 0 green;
  border: none;
}

.mob_basket_card_counter {
  width: 50%;
  height: 60px;
  font-size: 3vw;
  outline: none;
  box-shadow: 1px 1px 2px 0 green;
  border: none;
}

.modal_basket_card_counter_txt {
  color: grey;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
.mob_modal_basket_card_counter_txt {
  color: grey;
  font-size: 3vw;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.modal_card_container_title_price_box {
  width: 40%;
  height: 40px;
  border-radius: 20px;
  border: solid;
  border-color: #818181;
  color: #818181;
  font-size: 18px;
  text-align: center;
  line-height: 40px;
  overflow: hidden;
  white-space: pre;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.mob_modal_card_container_title_price_box {
  width: 40%;
  height: 80px;
  border-radius: 20px;
  border: solid;
  border-color: #818181;
  color: #818181;
  font-size: 5vw;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  line-height: 40px;
  overflow: hidden;
  white-space: pre;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modal_card_container_content_box {
  width: 95%;
  height: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.mob_modal_card_container_content_box {
  width: 95%;
  height: 70%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.modal_card_container_content_txt_box {
  width: 70%;
  height: 95%;
  text-align: justify;
  color: #818181;
  font-size: 13px;
  line-height: 18px;
  overflow-y: Auto;
  box-sizing: border-box;
  padding: 0 10px 0 10px;
  /* background-color: rgb(255, 131, 131); */
}
.mob_modal_card_container_content_txt_box {
  width: 95%;
  text-align: justify;
  font-weight: normal;
  font-size: 4vw;
  box-sizing: border-box;
  padding: 0 10px 0 10px;
  line-height: 4.2vw;
  /*   background-color: rgb(131, 143, 255); */
}

.modal_card_container_content_img_box {
  width: 30%;
  height: 95%;
}

.mob_modal_card_container_content_img_box {
  width: 60%;
  height: 50%;
  padding: 4vw;
}

.modal_card_container_content_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: zoom-in;
}

.modal_card_container_content_img:active {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: fixed;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  border: 1px solid black;
  background-color: rgba(209, 0, 218, 0.7);
  z-index: 9993;
}

.modal_card_review_form {
  height: 100%;
}

.modal_card_review_title {
  width: 100%;
  font-size: 2vw;
  color: #a067a1;
  text-align: center;
}

.modal_card_star_rating {
  width: 65%;
  outline: none;
  display: flex;
  justify-content: flex-end;
}

.mob_modal_card_star_rating {
  width: 60%;
  outline: none;
  display: flex;
  justify-content: center;
}

.modal_card_input_name {
  width: 60%;
  font-size: 14px;
  color: #a067a1;
  outline: none;
  border: none;
  border-bottom: 1px solid grey;
}

.order_checkout_add_textarea_box {
  height: 50%;
}

.modal_card_input_review {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  font-size: 14px;
  color: #a067a1;
  background: #f4f4f4;
  outline: none;
  border: none;
  overflow-y: auto;
  resize: none;
}

.order_card_send_btn_review_box {
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order_card_send_btn_review {
  width: 150px;
  height: 30px;
  font-size: 14px;
  letter-spacing: 1px;
  background-color: #b8f5b8;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #a067a1;
  cursor: pointer;
  outline: none;
}

.order_card_send_btn_review:hover {
  background-color: #fffb99;
}

.modal_card_container_order_box {
  width: 95%;
  height: 15%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.mob_modal_card_container_order_box {
  width: 95%;
  height: 15vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(26, 13, 206); */
}

.modal_card_container_content_order_box {
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(255, 255, 255); */
}

.mob_modal_card_container_content_order_box {
  width: 33.3%;
  height: 8vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(255, 255, 255); */
}

.modal_card_container_content_order_btn {
  width: 250px;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  color: #a067a1;
  text-transform: uppercase;
  background: #b8f5b8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}
.mob_modal_card_container_content_order_btn {
  width: 250px;
  height: 8vw;
  font-size: 3.5vw;
  font-weight: bold;
  color: #a067a1;
  text-transform: uppercase;
  background: #b8f5b8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.modal_card_container_content_order_btn_active {
  background: #fffb99;
}
.modal_card_container_content_order_btn:hover {
  background: #fffb99;
}

/* Модалка TopBar */
.modal_topbar_container {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.page_counter_ul {
  list-style-type: none;
  height: 10%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.page_counter_li {
  width: 20px;
  height: 10px;
  cursor: pointer;
}

.page_counter_li_circle {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: rgb(124, 124, 124);
}

.page_counter_li_circle:hover {
  transform: scale(1.03, 1.03);
  background-color: rgb(122, 0, 0);
}

.page_counter_li_circle_active {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: rgb(122, 0, 0);
}

.modal_topbar_arrowLeft_box {
  width: 10%;
  height: 100%;
  position: relative;
}

.modal_topbar_content_wrapper {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.modal_topbar_content_category {
  width: 100%;
  height: 10%;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  color: #a067a1;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modal_topbar_content_title {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: bold;
  color: #818181;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modal_topbar_content_box {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  font-size: 32px;
  font-weight: bold;
  color: #818181;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
}

.modal_topbar_arrowRight_box {
  width: 10%;
  height: 100%;
  position: relative;
}

.arrow_box_left {
  width: 50px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 40%;
  cursor: pointer;
  transform: rotate(-90deg);
}

.arrow_box_right {
  width: 50px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 40%;
  cursor: pointer;
  transform: rotate(90deg);
}

.arrow_box_right .arrow_right {
  width: 70px;
  height: 5px;
  background-color: rgb(202, 202, 202);
  position: absolute;
  left: 23px;
  transform: rotate(20deg);
}

.arrow_box_right .arrow_left {
  width: 70px;
  height: 5px;
  background-color: rgb(202, 202, 202);
  position: absolute;
  right: 20px;
  transform: rotate(-20deg);
}

.arrow_box_right:hover .arrow_left {
  height: 7px;
  background-color: rgb(143, 143, 143);
}
.arrow_box_right:hover .arrow_right {
  height: 7px;
  background-color: rgb(143, 143, 143);
}

.arrow_box_left .arrow_right {
  width: 70px;
  height: 5px;
  background-color: rgb(202, 202, 202);
  position: absolute;
  left: 23px;
  transform: rotate(20deg);
}

.arrow_box_left .arrow_left {
  width: 70px;
  height: 5px;
  background-color: rgb(202, 202, 202);
  position: absolute;
  right: 20px;
  transform: rotate(-20deg);
}

.arrow_box_left:hover .arrow_left {
  height: 7px;
  background-color: rgb(143, 143, 143);
}
.arrow_box_left:hover .arrow_right {
  height: 7px;
  background-color: rgb(143, 143, 143);
}

.correct_img {
  object-fit: contain;
}

/* .delivery_cost_calc_box{
    width:300px;
    height:500px; 
    position: relative;
    position:absolute;
    top:0%;
    left:0%;
} */

.delivery_cost_calc_box_iframe {
  width: 100%;
  height: 500px;
  position: absolute;
  top: 0%;
  left: 0%;
}

/* CSS for table "Стоимость доставки" content */
.delivery_cost_table {
  width: 50%;
  background: rgb(255, 255, 255);
  color: #444444;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  border-spacing: 1px;
  border-collapse: collapse;
  border: 2px solid;
}
.delivery_cost_table_th {
  background-color: rgb(254, 176, 255);
  border: 2px solid;
}

.delivery_cost_table_td {
  padding: 5px; /* Поля вокруг текста */
  border: 1px solid;
}
.delivery_cost_table_th {
  padding: 5px; /* Поля вокруг текста */
  border: 1px solid;
}

.mystyle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
