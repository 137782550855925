.content_gallery_box {
  width: 95%;
  height: 46%;
  overflow: hidden;
  margin: auto auto;
}

.content_gallery_title {
  width: 100%;
  height: 13vw;
  font-size: 1.9vw;
}

.content_gallery_img {
  width: 100%;
  height: 120vw;
  border-radius: 1vw;
}

.content_gallery_box_spinner {
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
